<template>
    <Layout navbarAbsolute navbarTransparent>
        <div class="ql-editor pb-5 px-0">
            <img src="@/static/assets/event/item1.png" class="w-100 mask1" style="" />

            <div style="background-color: #FBFBFB">
                <div class="container py-5">
                    <div class="my-5 d- w-100 justify-content-center ql-editor" id="EVT-1">
                        <h1 class="display-4 text-primary-darker text-center font-weight-bolder" style="max-width:700px">Why Events Are Important For Your Career Level</h1>
                    </div>

                    <div class="row align-items-start my-5">
                        <div class="col-lg-6 d-flex flex-column align-items-center">
                            <img src="@/static/assets/event/item3.png" class="w-100 mt-auto mb-3" style="max-width:150px" />
                            <span class="" id="EVT-2">
                                <h2 class="text-center font-weight-bolder text-primary-darker">People & Organization Transformation</h2>
                                <p class="text-center" style="max-width:500px">
                                    The most important part of an organization is people. Transformation of people and organization, including management systems, is needed to be able to win in the midst of rapid changes that occur.
                                </p>
                            </span>
                        </div>
                        <div class="col-lg-6 d-flex flex-column align-items-center">
                            <img src="@/static/assets/event/item2.png" class="w-100 mt-auto mb-3" style="max-width:150px" />
                            <span class="" id="EVT-3">
                                <h2 class="text-center font-weight-bolder text-primary-darker">Technology Development</h2>
                                <p class="text-center" style="max-width:500px">
                                    It is almost impossible to excel in today's era without being at the forefront of using technology as part of who we are. Technological developments are what you really need. 
                                </p>
                            </span>
                        </div>
                    </div>

                </div> 

                <div class="position-relative mb-5">
                    <div class="position-absolute w-100" style="height:250px;top:40%;background-color:#E8F1F8">
                    </div>
                    <div class="container">
                        <div class="my-5 d-flex w-100 justify-content-center" id="EVT-4">
                            <h1 class="display-4 text-primary-darker text-center font-weight-bolder" style="max-width:700px">Our Upcoming Events</h1>
                        </div>
                        <div class="row"> 
                            <template v-for="(data,index) in data_list">
                                <div class="col-lg-4" :key="index+'EVENTCARD'">
                                    <router-link :to="`/events/${data.event_id}`">
                                        <div class="card-event rounded p-1 px-2 shadow-lg">
                                            <img :src="data.event_thumbnail" class="w-100 rounded" style="max-height:276px;object-fit:cover" />
                                            
                                            <div class="d-flex mt-2" style="justify-content:space-between">
                                                <div class="rounded px-1 font-weight-bolder d-flex align-items-center" style="padding:.5em;color:#EF6D22;border: 1px solid #EF6D22;font-size:12px">
                                                    {{data.event_type}}
                                                </div>
                                                <div class="rounded font-weight-bolder" style="padding:.5em;color:white;background-color: #EF6D22;border:2px solid #EF6D22;font-size:12px">
                                                    {{data.event_price ? 'Paid' : 'Free'}} Event
                                                </div>
                                            </div>
                                            
                                            <h3 class="text-primary-main font-weight-bolder mt-2 mb-0" style="font-size:20px">{{data.event_title}}</h3>
                                            <div style="font-size:14px;" class="text-primary-main mt-0">
                                                {{moment(data.event_startdate).fromNow()}}
                                            </div>

                                            <div class="d-flex p-1 rounded-lg mt-1" style="background-color:#E8F1F8;font-size:14px !important;color: black !important">
                                                <span class="mr-auto">Kuota : {{data.event_quota}}</span>
                                                <span class="d-flex align-items-center" style="gap:5px">
                                                    <span class="mr-1">Share with</span>
                                                    <div class="d-flex text-primary-main" style="gap:5px;font-size:20px">
                                                        <i class="bx bxl-whatsapp"></i>
                                                        <i class="bx bxl-linkedin"></i>
                                                        <i class="bx bxl-telegram"></i>
                                                        <i class="bx bxl-facebook"></i>
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </router-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <div class="fab-help px-2 py-1 rounded-pill" v-if="more_data" @click="getNextDatas">
                        See More Events
                    </div> 
                </div>

            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from '@/layouts/main.vue'
import store from '@/store'
import moment from 'moment'

export default {
    components: {
        Layout
    },
    methods: {
        async getNextDatas(page=1){
            let temp = await store.dispatch(`events/getDataList`, {
                page: page ? page : this.page,
                lang: this.currentLocale,
                perpage: this.max_render,  
            }) 
            this.data_list = this.data_list ? this.data_list.concat(temp.data) : temp.data
            this.more_data = ( this.page * this.max_render ) < temp.total
            this.page = this.page + 1
            this.$forceUpdate()
            this.init = false
        },
        async getLocales(){
            let temp = await store.dispatch(`page_content/getDataList`, {
                lang_code: this.currentLocale,
                code: 'EVT',  
            }) 
            temp?.map((item, key) => {
                document.getElementById(item.code).innerHTML = item.content
            })
            this.$forceUpdate()
        },
    },
    mounted(){
        this.getNextDatas()
        this.getLocales() 
    },
    computed: {
        currentLocale() {
            return this.$store.state.locales.CURRENT
        },
    },
    watch: {
        currentLocale(val) {
            this.getLocales();
        },
    },
    data(){
        return {
            max_render: 10,
            page: 1,
            data_list: [],
            total: 0,
            moment,
        }
    }
}
</script>

<style scoped>
.mask1 {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 90%);
}
.card-event {
    background-color: white;
    cursor: pointer;
}
.card-event:hover {
    background-color: rgb(228, 234, 255);
}
</style>